import React from 'react';
import ReactDOM from 'react-dom';
import { HomeApp } from './HomeApp';
import './styles/styles.scss';




ReactDOM.render(
    <HomeApp />,
  document.getElementById('root')
);


