import React, { useEffect, useState } from 'react';
import validator from 'validator';
import Swal from 'sweetalert2'
import emailjs from 'emailjs-com'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

export const HomeApp = () => {



    const [value, setValue] = useState({
        nombre: '',
        correo: '',
        celular: '',
    })

    const [check, setCheck] = useState(false)

    const [error, setError] = useState(null)

    useEffect(() => {
        setTimeout(() => {
            setError(null)
        }, 5000)
    }, [error])

    const handleChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }

    const { nombre, celular, correo } = value;

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formValid()) {

            console.log('email enviado...')
            emailjs.sendForm(
                'service_lfbdo3w',
                'template_lhofxyy',
                e.target,
                'user_VvKgMY32TB5uIO72jHyqe')
                .then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err)
                })
        }


    }

    const handleCheck = (e) => {
        setCheck(true)
    }

    const formValid = () => {

        if (nombre.trim().length === 0) {
            setError('Nombre incorrecto')
            return false;
        } else if (!validator.isEmail(correo)) {
            setError('Correo incorrecto')
            return false;
        } else if (!validator.isNumeric(celular)) {
            setError('Celular incorrecto')
            return false;
        } else if (check === false) {
            setError('Acepta los terminos y condiciones')
            return false;
        }

        Swal.fire({
            title: '¡Formulario enviado con exito!',
            text: 'Bienvenido a Home Arquitectura, pronto nos comunicaremos contigo',
            icon: 'success',
            confirmButtonText: 'OK'
        })

        setValue({
            nombre: '',
            correo: '',
            celular: ''
        })

        return true;

    }

    return (


        <div className='container__main'>

            <div className='container'>

                <h2>Contáctanos</h2>
                <div className='wrap__info'>
                    <div className='container__info uno'>
                        <FontAwesomeIcon className='icon' icon={faWhatsapp} />

                        <p>
                            312 375  5065 | 320 835 1630
                        </p>
                    </div>
                    <div className='container__info dos'>
                        <FontAwesomeIcon className='icon' icon={faEnvelope} />
                        <p>
                            proyectos@homearquitectura.com
                        </p>
                    </div>
                </div>

                <div className='container__form'>


                    <div className='logo__wrap'>
                        <img
                            src={'./assets/logo-02.png'}
                            alt='logotipo'
                        />
                        <div className='btn'>
                            <button
                                className='button__cataologo'
                            >
                                <a href={'assets/pv/HomeArquitectura.pdf'} rel='noreferrer' target='_blank'>Conoce nuestros proyectos</a>

                            </button>
                        </div>

                    </div>



                    <form
                        onSubmit={handleSubmit}
                    >
                        {
                            error && (
                                <div className='error'>
                                    <p>{error}</p>
                                </div>
                            )
                        }

                        <input
                            name='nombre'
                            value={nombre}
                            onChange={handleChange}
                            autoComplete='off'
                            placeholder='Nombre'
                        />

                        <input
                            name='correo'
                            value={correo}
                            onChange={handleChange}
                            autoComplete='off'
                            placeholder='Correo'
                        />


                        <input
                            name='celular'
                            value={celular}
                            onChange={handleChange}
                            autoComplete='off'
                            placeholder='Celular'
                        />

                        <div className='wrap'>
                            <input
                                name='check'
                                onChange={handleCheck}
                                type='checkbox'
                            />
                            <p>He leido y acepto <a href={'assets/pv/Home-Arquitectura.pdf'} rel='noreferrer' target='_blank'>la politica y privacidad</a></p>
                        </div>

                        <button
                            className="submit"
                            type="submit"
                        >
                            Enviar
                        </button>


                    </form>
                </div>
            </div>


        </div>
    )
}


